<template>
    <v-container fill-height>
        <material-card
                style="width:100%"
                :title="factory.name">
            <v-card flat>
                <v-card-text>
                    <h2 class="headline">Factory info</h2>
                    <v-divider></v-divider>
                    <v-text-field
                            required
                            :error-messages="checkFactoryName"
                            @input="$v.factory.name.$touch()"
                            @blur="$v.factory.name.$touch()"
                          v-model="factory.name"
                          :counter="200"
                          label="Name"

                        ></v-text-field>
                    <v-text-field
                          v-model="factory.website"
                          :counter="200"
                          required
                          :error-messages="checkFactoryWebsite"
                          @input="$v.factory.website.$touch()"
                          @blur="$v.factory.website.$touch()"
                          label="Website"

                        ></v-text-field>

                    <v-text-field
                          v-model="factory.fob_location"
                          required
                          :error-messages="checkFactoryFobLocation"
                          @input="$v.factory.fob_location.$touch()"
                          @blur="$v.factory.fob_location.$touch()"
                          :counter="200"
                          label="FOB Location"

                        ></v-text-field>
                    <h2 class="headline">User info</h2>
                    <v-divider></v-divider>
                    <v-text-field
                            required
                            :error-messages="checkUserFirstName"
                            @input="$v.factory.user.first_name.$touch()"
                            @blur="$v.factory.user.first_name.$touch()"
                          v-model="factory.user.first_name"
                          :counter="200"
                          label="Contact First Name"

                        ></v-text-field>

                    <v-text-field
                            v-model="factory.user.last_name"
                            :counter="200"
                            label="Contact Last Name"
                    ></v-text-field>

                    <v-text-field
                            required
                            :error-messages="checkUserEmail"
                            @input="$v.factory.user.email.$touch()"
                            @blur="$v.factory.user.email.$touch()"
                            v-model="factory.user.email"
                            :counter="200"
                            label="Contact Email"

                    ></v-text-field>

                    <v-text-field
                            required
                            :error-messages="checkUserProfilePhone"
                            @input="$v.factory.user.profile.phone.$touch()"
                            @blur="$v.factory.user.profile.phone.$touch()"
                            v-model="factory.user.profile.phone"
                            :counter="21"
                            label="Phone Number"

                    ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            outlined
                            color="blue-grey darken-4"
                            @click="save_factory(factory)"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </material-card>
    </v-container>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required, maxLength, minLength, email} from 'vuelidate/lib/validators'

    export default {
        components: {},
        mixins: [validationMixin],

        validations: {
            factory: {
                name: {
                    required,
                },
                website: {
                    required
                },
                fob_location: {
                    required
                },
                user: {
                    first_name: {
                        required,
                    },
                    email: {
                        required,
                        email
                    },
                    profile: {
                        phone: {
                            required
                        }
                    }
                }
            }
        },
        data() {
            return {
                factory_id: 0,
                factory: {
                    user:{
                        profile:{}
                    }
                }
            }
        },
        computed: {
            checkFactoryName() {
                const errors = [];
                if (!this.$v.factory.name.$dirty) return errors;
                !this.$v.factory.name.required && errors.push('factory.name is required.');
                return errors;
            },
            checkFactoryWebsite() {
                const errors = [];
                if (!this.$v.factory.website.$dirty) return errors;
                !this.$v.factory.website.required && errors.push('factory.website is required.');
                return errors;
            },
            checkFactoryFobLocation() {
                const errors = [];
                if (!this.$v.factory.fob_location.$dirty) return errors;
                !this.$v.factory.fob_location.required && errors.push('factory.fob_location is required.');
                return errors;
            },
            checkUserFirstName() {
                const errors = [];
                if (!this.$v.factory.user.first_name.$dirty) return errors;
                !this.$v.factory.user.first_name.required && errors.push('factory.user.first_name is required.');
                return errors;
            },
            checkUserEmail() {
                const errors = [];
                if (!this.$v.factory.user.email.$dirty) return errors;
                !this.$v.factory.user.email.email && errors.push('Email must be a valid email');
                !this.$v.factory.user.email.required && errors.push('factory.user.email is required.');
                return errors;
            },
            checkUserProfilePhone() {
                const errors = [];
                if (!this.$v.factory.user.profile.phone.$dirty) return errors;
                !this.$v.factory.user.profile.phone.required && errors.push('factory.user.profile.phone is required.');
                return errors;
            },
        },
        created() {
            this.factory_id = this.$route.params.factory_id;
            this.load_factory();
        },
        methods: {
            load_factory() {
                this.$hget(
                    this.$http.defaults.baseURL + '/sourcers/organisations/' + this.factory_id + '/').then((response) => {
                    if (typeof response.data !== 'undefined') {
                        this.factory = response.data;
                        console.log(response);
                    }
                });
            },
            save_factory(factory) {
                if(!factory.invoice_code || factory.invoice_code === '')
                {
                    factory.invoice_code = 'FA-'+factory.id;
                }
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$notify({
                        title: 'Error:',
                        text: 'Please Fill Out The Form Correctly',
                        group: 'notifications',
                        position: 'top right'
                    });
                    return false;
                }
                this.$hpatch(
                    this.$http.defaults.baseURL + '/sourcers/organisations/' + this.factory_id + '/', factory).then((response) => {
                    if (typeof response.data !== 'undefined') {
                        this.factory = response.data;
                        this.save_user(factory);
                    }
                });
            },
            save_user(factory)
            {
                this.$hpost(this.$http.defaults.baseURL + '/sourcers/org-user/', factory).then((response) => {
                    if(typeof response.data !== 'undefined')
                    {
                        this.$notify({
                            title: 'Success:',
                            text: 'Saved Successfully.',
                            group: 'notifications',
                            position: 'top right'
                        });
                    }
                });
            }
        }
    }
</script>
